import { Link } from "react-router-dom";

// Components
import Banner from "../components/Banner";

//Icon
import ImgEllipse1 from "../assets/decoration/ellipse-1.png";
import ImgEllipse2 from "../assets/decoration/ellipse-2.png";
import ImgStar2 from "../assets/decoration/star-2.png";
import ImgLine1 from "../assets/decoration/line-1.png";
import ImgBell1 from "../assets/decoration/bell-1.png";

// Image
import agencyJakarta from "../assets/services/digital-agency-jakarta.png";
import ImgWebDev from "../assets/services/website-dev.png";
import ImgSocMed from "../assets/services/socmed.png";
import ImgDigAd from "../assets/services/digital-ads.png";
import ImgBusDev from "../assets/services/business-dev.png";
import ImgSms from "../assets/services/sms-blast.png";
import ImgEcom from "../assets/services/ecommerce.png";

// Styling
import "../pages/Services.css";
import "../index.css";

const Services = () => {
  return (
    <>
      <Banner>
        <div className="banner-text-service">
          <h1 className="fs-62 text-center text-white fw-bold">
            Technology is dominated by two types of people:
          </h1>
          <p className="fs-24 text-center text-white fw-bold">
            {" "}
            those who understand what they do not manage and those who manage
            what they do not understand.
          </p>
          <i className="d-block fs-20 text-center text-white">
            - Archibald Putt
          </i>
        </div>
      </Banner>
      <div className="services">
        <div className="d-none d-md-block position-relative">
          <div className="line-1">
            <img src={ImgLine1} alt="" />
          </div>
          <div className="bell-1">
            <img src={ImgBell1} alt="" />
          </div>
          <div className="ellipse-2">
            <img src={ImgEllipse2} alt="" />
          </div>
        </div>
        <div className="container">
          <div className="row" style={{ padding: "8rem 0" }}>
            <div className="col-12 col-md-6 align-self-center">
              <div>
                <img
                  className="d-block m-auto img-fluid"
                  src={agencyJakarta}
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-md-6 align-self-center">
              <p className="fs-40 text-black m-0 text-end vaya-description">
                Vaya Indonesia provides integrated digital marketing solutions
                that cater to your brands needs.
              </p>
            </div>
          </div>
        </div>
        <div className="position-relative">
          <div className="d-none d-md-block ellipse-1">
            <img src={ImgEllipse1} alt="" />
          </div>
          <div className="d-none d-md-block star-1">
            <img src={ImgStar2} alt="" />
          </div>
        </div>
        <div className="container">
          <div style={{ padding: "8rem 0" }}>
            <div className="row row-cols-1 row-cols-md-3 g-2">
              <div className="col">
                <div className="card">
                  <img src={ImgWebDev} alt="" />
                  <div className="card-text">
                    <p className="text-white">
                      VAYA selalu mengutakaman pentingnya realitas, dan
                      keserasian untuk situs web anda. Kami telah memiliki tim
                      UI/UX design yang handal dalam membuat design website
                      serta developer yang sudah berpengalaman dalam membangun
                      sebuah website.
                    </p>
                    <Link to="/services-detail">
                      <button className="btn btn-transparent text-white">
                        LEARN MORE
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card">
                  <img src={ImgSocMed} alt="" />
                  <div className="card-text">
                    <p className="text-white">
                      VAYA memiliki layanan social media management yaitu
                      layanan yang dapat membantu anda untuk meningkatkan brand
                      awareness bisnis anda. Vaya memiliki tim social media
                      khusus yang siap memberikan strategi terbaik untuk bisnis
                      kamu.
                    </p>
                    <Link to="/services-detail">
                      <button className="btn btn-transparent text-white">
                        LEARN MORE
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card">
                  <img src={ImgDigAd} alt="" />
                  <div className="card-text">
                    <p className="text-white">
                      Visibilitas adalah kunci sukses dalam pemasaran online
                      atau bisnis e-commerce. Pemasaran digital melakukan hal
                      itu, baik melalui iklan penelusuran, iklan bergambar, atau
                      iklan video. Kami berkomitmen untuk meningkatkan bisnis
                      anda ke tingkat yang tidak pernah anda bayangkan
                      sebelumnya, meningkatkan visibilitas anda ke jutaan orang
                      secara online.
                    </p>
                    <Link to="/services-detail">
                      <button className="btn btn-transparent text-white">
                        LEARN MORE
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card">
                  <img src={ImgBusDev} alt="" />
                  <div className="card-text">
                    <p className="text-white">
                      Sebagai Digital Marketing Agency, kami sangat memahami
                      bahwa mengkomunikasikan bisnis beserta ide dan produknya
                      itu menantang. Oleh karena itu, bersama kami, anda akan
                      mendapatkan strategi dan ide untuk memasarkan produk anda
                      berdasarkan ratusan pengalaman pelaku bisnis lain yang
                      telah menjadi klien kami. Kami menjamin bahwa anda bekerja
                      sama dengan salah satu agen pemasaran digital terbaik di
                      Indonesia.
                    </p>
                    <Link to="/services-detail">
                      <button className="btn btn-transparent text-white">
                        LEARN MORE
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card">
                  <img src={ImgSms} alt="" />
                  <div className="card-text">
                    <p className="text-white">
                      VAYA menyediakan layanan SMS Broadcast untuk bisnis anda
                      dengan solusi praktis dan mudah untuk memberikan informasi
                      dan promosi yang tepat untuk meningkatkan bisnis anda
                      secara efisien agar segera diterima oleh calon pelanggan
                      anda. Layanan yang saat ini kami tawarkan kepada anda
                      adalah: SMS Broadcast, SMS LBA, SMS Trigger, dan SMS Long
                      Number.
                    </p>
                    <Link to="/services-detail">
                      <button className="btn btn-transparent text-white">
                        LEARN MORE
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="card">
                  <img src={ImgEcom} alt="" />
                  <div className="card-text">
                    <p className="text-white">
                      VAYA adalah solusi Manajemen E-Commerce bagi anda yang
                      memiliki bisnis dari berbagai industri untuk dijual di
                      platform marketplace. Kami dapat membantu anda untuk
                      menata marketplace agar lebih interaktif dan menarik untuk
                      meningkatkan penjualan anda, dengan team terbaik kami yang
                      sudah berpengalaman dalam dunia e-commerce kami siap
                      membantu anda.
                    </p>
                    <Link to="/services-detail">
                      <button className="btn btn-transparent text-white">
                        LEARN MORE
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
