import { FaTimes } from "react-icons/fa";
import "../index.css";

const Sidebar = (props) => {
  const clickSidebar = () => {
    props.setIsExpand(!props.isExpand);
  };
  return (
    <>
      <div
        className="d-none d-md-block sidebar p-2"
        style={props.isExpand ? { marginRight: 0 } : { marginRight: "-250px" }}
      >
        <div className="close-btn">
        <FaTimes size={25} className="" onClick={clickSidebar} role="button" />
        </div>
        <ul className="p-3">
          <li>
            <a href="/">HOME</a>
          </li>
          <li>
            <a href="/about-us">ABOUT US</a>
          </li>
          <li>
            <a href="/services">SERVICES</a>
          </li>
          <li>
            <a href="/news">NEWS</a>
          </li>
          <li>
            <a href="/case-study">CASE STUDY</a>
          </li>
          <li>
            <a href="/contact-us">CONTACT US</a>
          </li>
        </ul>
      </div>
      <div
        className="d-block d-md-none sidebar p-2"
        style={props.isExpand ? { marginRight: 0 } : { marginRight: "-500px" }}
      >
        <FaTimes size={25} className="" onClick={clickSidebar} role="button" />
        <ul className="p-3">
          <li>
            <a href="/">HOME</a>
          </li>
          <li>
            <a href="/about-us">ABOUT US</a>
          </li>
          <li>
            <a href="/services">SERVICES</a>
          </li>
          <li>
            <a href="/news">NEWS</a>
          </li>
          <li>
            <a href="/case-study">CASE STUDY</a>
          </li>
          <li>
            <a href="/contact-us">CONTACT US</a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
