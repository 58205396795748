import { Link } from "react-router-dom";

// Components
import ScrollAnimation from "react-animate-on-scroll";
import Banner from "../components/Banner";
import { caseStudyData } from "../assets/data/case-study";

// Image
import ImgComputerScreen from "../assets/caseStudy/computer-screen.png";
import ImgTube from "../assets/decoration/tube.png";
import ImgEllipse3 from "../assets/decoration/ellipse-3.png";

// Styling
import "../pages/CaseStudy.css";

const Portfolio = () => {
  return (
    <>
      <Banner>
        <div className="row case-study-banner">
          <div className="col-12 col-lg-6 align-self-center">
            <h1 className="fs-62 fw-bold text-white text-center">
              Our Case Study
            </h1>
          </div>
          <div className="col-12 col-lg-6">
            <div className="d-none d-md-block case-study-image">
              <img src={ImgComputerScreen} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
        
      </Banner>
      <div className="position-relative">
        <div className="round-case-study">
          <img
            src={ImgEllipse3}
            alt=""
            className="d-none d-lg-block img-fluid"
          />
        </div>
      </div>

      <div className="container portfolio">
        <h2 className="text-black fs-52 fw-bold text-center mt-5">
          Our Portfolio
        </h2>
        <div className="my-5">
          <div className="row row-cols-1 row-cols-md-4 g-2">
            {caseStudyData.map((item, index) => {
              return (
                <div className="col">
                  <ScrollAnimation animateIn="fadeIn">
                    <div className="card">
                      <img
                        src={item?.image}
                        className="img-fluid card-img-top"
                        alt="case-study.png"
                      />
                      <div className="card-body">
                        <h5 className="card-title fw-bold">{item?.title}</h5>
                        <p className="card-text text-truncate">
                          {item?.description}
                        </p>
                        <Link
                          className="text-decoration-none text-black"
                          to={`/case-study/${item?.id}`}
                        >
                          <p className="card-text m-0 fw-bold">Selengkapnya</p>
                        </Link>
                      </div>
                    </div>
                  </ScrollAnimation>
                </div>
              );
            })}
          </div>
        </div>
        {/* <div className="mb-5">
          <ScrollAnimation animateIn="fadeIn">
            <div className="row row-cols-1 row-cols-md-4 g-2">
              <div className="col">
                <a className="text-decoration-none" href="/case-study-1">
                  <div className="card bg-portfolio-1 p-2">
                    <h1 className="fs-24 text-white">WEBSITE</h1>
                    <div className="position-relative">
                      <div className="">
                        <img
                          className="d-block m-auto w-100 h-auto"
                          src={Img3Laptop}
                          alt=""
                        />
                      </div>
                    </div>
                    <h1 className="fs-24 text-white text-end">DEVELOPER</h1>
                  </div>
                </a>
              </div>
              <div className="col">
                <a className="text-decoration-none" href="/news-detail">
                  <div className="card bg-portfolio-2 p-2">
                    <h1 className="fs-24 text-white">COMPANY</h1>
                    <div className="position-relative">
                      <div className="">
                        <img
                          className="d-block m-auto w-75 h-auto"
                          src={ImgBook}
                          alt=""
                        />
                      </div>
                    </div>
                    <h1 className="fs-24 text-white text-end">PROFILE</h1>
                  </div>
                </a>
              </div>
              <div className="col">
                <a href="/news-detail" className="text-decoration-none">
                  <div className="card bg-portfolio-3 p-2">
                    <h1 className="fs-24 text-white">SOCIAL</h1>
                    <div className="position-relative">
                      <div className="">
                        <img
                          className="d-block m-auto w-100 h-auto"
                          src={Img3Phone}
                          alt=""
                        />
                      </div>
                    </div>
                    <h1 className="fs-24 text-white text-end">MEDIA</h1>
                  </div>
                </a>
              </div>
              <div className="col">
                <a href="/news-detail" className="text-decoration-none">
                  <div className="card bg-portfolio-4 p-2">
                    <h1 className="fs-24 text-white">SMS</h1>
                    <div className="position-relative">
                      <div className="" style={{ width: 270, height: "auto" }}>
                        <img
                          className="d-block m-auto w-100 h-auto"
                          src={Img1Laptop}
                          alt=""
                        />
                      </div>
                    </div>
                    <h1 className="fs-24 text-white text-end">BLAST</h1>
                  </div>
                </a>
              </div>
            </div>
          </ScrollAnimation>
        </div> */}
      </div>
      {/* <div className="d-none d-lg-block container-fluid p-0 case-study-content">
        <div className="position-relative">
          <img className="w-100 h-100" src={caseStudyBg} alt="" />
          <div className="text-content">
            <p className="text-honeycomb fw-bold m-0">CASE STUDY</p>
            <h1 className="fs-56 text-white fw-bold m-0">Digital</h1>
            <h1 className="fs-56 text-white fw-bold m-0">Ads</h1>
            <div className="py-4">
              <p className="text-white fs-20 m-0">
                They want to increase their profile visit
              </p>
              <p className="text-white fs-20 m-0">
                and brand awareness for their law firm
              </p>
              <p className="text-white fs-20 m-0">using Instagram ads.</p>
            </div>
            <p className="text-honeycomb fw-bold fs-20 m-0">
              The result from the ads within 5 days,
            </p>
            <p className="text-honeycomb fw-bold fs-20 m-0">They’ve got :</p>
            <div>
              <img className="img-fluid" src={Statistic} alt="" />
            </div>
          </div>
        </div>
      </div> */}
      <div className="position-relative">
        <div className="d-none d-lg-block tube-case-study">
          <img className="img-fluid" src={ImgTube} alt="" />
        </div>
      </div>
      {/* <Outlet/> */}
    </>
  );
};

export default Portfolio;
