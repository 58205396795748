// Components
import Banner from "../components/Banner";
import ScrollAnimation from "react-animate-on-scroll";

//Icon
import ImgHalfRound1 from "../assets/decoration/half-round-3d.png";
import ImgHalfRound2 from "../assets/decoration/half-round-2.png";
import ImgOrnament4 from "../assets/decoration/ornament-4.png";
import ImgEllipse1 from "../assets/decoration/ellipse-1.png";
import ImgEllipse2 from "../assets/decoration/ellipse-2.png";
import ImgPolygon2 from "../assets/decoration/polygon-2.png";
import ImgPartyPopper from "../assets/decoration/party-popper.png";
import ImgStar2 from "../assets/decoration/star-2.png";
import ImgLine2 from "../assets/decoration/line-2.png";
import ImgBell1 from "../assets/decoration/bell-1.png";
import ImgGraph1 from "../assets/decoration/graph-1.png";

// Image
import ImgMegaphone from "../assets/aboutUs/rectangle.png";
import ImgAboutUs1 from "../assets/aboutUs/about-us-img1.png";
import ImgAboutUs2 from "../assets/aboutUs/about-us-img2.png";
import ImgAboutUs3 from "../assets/aboutUs/about-us-img3.png";
import ImgAboutUs4 from "../assets/aboutUs/about-us-img4.png";
import ImgAboutUs5 from "../assets/aboutUs/about-us-img5.png";
import ImgAboutUs6 from "../assets/aboutUs/about-us-img6.png";
import ImgVisiMisi from "../assets/aboutUs/visi-misi.png";
import ImgSwim from "../assets/aboutUs/swim.png";
import LogoKreditPlus from "../assets/aboutUs/client/kreditplus-logo.png";
import Logolancome from "../assets/aboutUs/client/lancome-logo.png";
import LogoLippo from "../assets/aboutUs/client/lippo-logo.png";
import LogoZebra from "../assets/aboutUs/client/logo-zebra.png";
import LogoLoreal from "../assets/aboutUs/client/loreal-logo.png";
import LogoMedistra from "../assets/aboutUs/client/medistra-logo.png";
import LogoMiraeAsset from "../assets/aboutUs/client/mirae-asset-logo.png";
import LogoProfira from "../assets/aboutUs/client/profira-logo.png";
import LogoSinergiDigital from "../assets/aboutUs/client/sinergi-digital.png";
import LogoSynnex from "../assets/aboutUs/client/synnex-metrodata-indonesia.jpg";
import LogoThePeak from "../assets/aboutUs/client/thepeak-sudirman.jfif";
import LogoUNDSS from "../assets/aboutUs/client/UNDSS.png";

// CSS
// import "../pages/AboutUs.css";
import "../index.css";

const AboutUs = () => {
  return (
    <>
      <Banner>
        <div className="about-us-banner">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="text">
                <h1 className="text-white fs-62 fw-bold">Tentang Vaya</h1>
                <h5 className="text-white">
                  Vaya adalah agensi digital kreatif dan strategis layanan
                  lengkap yang melayani klien dari Indonesia. Menggunakan
                  strategi pemasaran dan pengembangan berbasis data, tujuan dari
                  setiap proyek yang kami mulai adalah untuk memberikan hasil
                  yang terukur kepada klien kami.
                </h5>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-none d-lg-block img">
                <img className="img-fluid" src={ImgMegaphone} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Banner>

      <div className="about-us position-relative">
        <div className="d-none d-lg-block half-round-2">
          <img src={ImgHalfRound2} alt="" />
        </div>
        <div className="d-none d-lg-block ellipse-1">
          <img src={ImgEllipse1} alt="" />
        </div>
        <ScrollAnimation animateIn="bounceInRight">
          <div className="container" style={{ paddingTop: "8rem" }}>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div>
                  <img
                    className="d-block m-auto img-fluid"
                    src={ImgAboutUs1}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 align-self-center">
                <h2 class="text-black text-head">Aktivasi Digital</h2>
                <p className="text-black fs-20">
                  Tim manajemen kami memiliki pengalaman selama puluhan tahun
                  dalam pemasaran online untuk menyediakan strategi pemasaran
                  online terintegrasi yang membantu perusahaan menumbuhkan
                  audiensnya. Kami memiliki pemahaman terbaik tentang budaya
                  internet dengan apresiasi terbaru.
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <div className="d-none d-md-block position-relative">
          <div className="polygon-2">
            <img src={ImgPolygon2} alt="" />
          </div>
          <div className="star-sm">
            <img src={ImgStar2} alt="" />
          </div>
        </div>
        <ScrollAnimation animateIn="bounceInLeft">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 align-self-center">
                <h2 class="text-black text-head">Strategi dan Perencanaan</h2>
                <p className="text-black fs-20">
                  Kami menyediakan layanan branding marketing untuk produk dan
                  jasa anda. Selain itu juga kami mempunyai jasa TVX, Print Ad,
                  Digital Campaign, BTL Event, dan Digital Strategis.
                </p>
              </div>
              <div className="col-12 col-lg-6 order-first order-md-last">
                <div>
                  <img
                    src={ImgAboutUs2}
                    alt=""
                    className="d-block m-auto img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <div className="d-none d-md-block position-relative">
          <div className="party-popper">
            <img src={ImgPartyPopper} alt="" />
          </div>
        </div>
        <ScrollAnimation animateIn="bounceInRight">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div>
                  <img
                    src={ImgAboutUs3}
                    alt=""
                    className="d-block m-auto img-fluid"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 align-self-center">
                <h2 class="text-black text-head">Solusi Terbaik</h2>
                <p className="text-black fs-20">
                  Kami telah membantu berbagai macam permasalahan perusahaan di
                  Indonesia dengan menghadirkan solusi digital dengan analisa
                  proses bisnis yang detail, kami yakin sekali dapat membantu
                  meningkatkan bisnis anda di sisi digital.
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <ScrollAnimation animateIn="bounceInLeft">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 align-self-center">
                <h2 class="text-black text-head">Tim Profesional</h2>
                <p className="text-black fs-20">
                  Vaya adalah tim profesional multikultural dan multidisiplin
                  yang berkumpul untuk membentuk tim dengan misi terfokus untuk
                  meningkatkan brand yang penuh perasaan di dunia digital dan
                  bersenang-senang saat melakukannya.
                </p>
              </div>
              <div className="col-12 col-lg-6 order-first order-md-last">
                <div>
                  <img
                    src={ImgAboutUs4}
                    alt=""
                    className="d-block m-auto img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <div className="position-relative">
          {/* <div className="d-none d-lg-block half-round-3d">
            <img src={ImgHalfRound1} alt="" />
          </div> */}
          <div className="d-none d-lg-block ornament-4">
            <img src={ImgOrnament4} alt="" />
          </div>
        </div>
        <ScrollAnimation animateIn="bounceInRight">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div>
                  <img
                    src={ImgAboutUs5}
                    alt=""
                    className="d-block m-auto img-fluid"
                  />
                </div>
              </div>
              <div className="col-12 col-lg-6 align-self-center">
                <h2 class="text-black text-head">Berorientasi pada Target</h2>
                <p className="text-black fs-20">
                  Sangat penting bagi sebuah perusahaan untuk mencapai goals
                  yang diinginkan. Untuk itu kami dengan senang hati membantu
                  anda mewujudkan goals tersebut sesuai dengan target yang sudah
                  ditetapkan.
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>
        <div className="d-none d-md-block position-relative">
          <div className="bell">
            <img src={ImgBell1} alt="" />
          </div>
        </div>
        <ScrollAnimation animateIn="bounceInLeft">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 align-self-center">
                <h2 class="text-black text-head">Kepuasan Klien</h2>
                <p className="text-black fs-20">
                  Kepuasan anda adalah kebahagiaan kami, untuk itu penting bagi
                  kami untuk membuat strategi untuk meningkatkan bisnis anda,
                  kami adalah mitra terbaik untuk bisnis anda dalam menghadapi
                  era serba digital.
                </p>
              </div>
              <div className="col-12 col-lg-6 order-first order-md-last">
                <div>
                  <img
                    src={ImgAboutUs6}
                    alt=""
                    className="d-block m-auto img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>

        <div className="py-5">
          <img
            src={ImgVisiMisi}
            alt=""
            className="d-block m-auto img-fluid px-2"
          />
        </div>
        <div className="position-relative">
          <div className="d-none d-lg-block graph">
            <img src={ImgGraph1} alt="" />
          </div>
          <div className="d-none d-lg-block ellipse-2">
            <img src={ImgEllipse2} alt="" />
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 align-self-center">
              <div>
                <img
                  src={ImgSwim}
                  alt=""
                  className="d-block m-auto img-fluid"
                />
              </div>
            </div>
            <div className="col-12 col-lg-6 align-self-center">
              <div className="mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-black fs-20 m-0">Pemasaran Digital</p>
                  <p className="text-black fs-20 m-0">96%</p>
                </div>
                <div class="progress">
                  <div
                    className="progress-bar bg-linear-2"
                    role="progressbar"
                    style={{ width: "96%" }}
                    aria-valuenow="96"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-black fs-20 m-0">Pengembangan Bisnis</p>
                  <p className="text-black fs-20 m-0">95%</p>
                </div>
                <div class="progress">
                  <div
                    className="progress-bar bg-linear-2"
                    role="progressbar"
                    style={{ width: "95%" }}
                    aria-valuenow="95"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-black fs-20 m-0">Pemasan & Penjualan</p>
                  <p className="text-black fs-20 m-0">98%</p>
                </div>
                <div class="progress">
                  <div
                    className="progress-bar bg-linear-2"
                    role="progressbar"
                    style={{ width: "98%" }}
                    aria-valuenow="98"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div className="mb-3">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="text-black fs-20 m-0">
                    Kreativitas & Inovasi
                  </p>
                  <p className="text-black fs-20 m-0">96%</p>
                </div>
                <div class="progress">
                  <div
                    className="progress-bar bg-linear-2"
                    role="progressbar"
                    style={{ width: "96%" }}
                    aria-valuenow="96"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-relative">
          <div className="line-2">
            <img src={ImgLine2} alt="" />
          </div>
        </div>
        <div className="our-client">
          <div className="text-center">
            <h4 className="text-black fs-52 fw-bold">Klien terbaik</h4>
            <p className="text-black fs-18 m-0">di Agency Kami</p>
          </div>
          <div className="mt-5">
            <div className="row row-cols-4 row-cols-md-3 row-cols-lg-6 g-4">
              <div className="col align-self-center">
                <div className="card border-0">
                  <div className="img-container">
                    <img
                      className="d-block m-auto img-fluid"
                      src={LogoKreditPlus}
                      alt="client-logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col align-self-center">
                <div className="card border-0">
                  <div className="img-container">
                    <img
                      className="d-block m-auto img-fluid"
                      src={Logolancome}
                      alt="client-logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col align-self-center">
                <div className="card border-0">
                  <div className="img-container">
                    <img
                      className="d-block m-auto img-fluid"
                      src={LogoZebra}
                      alt="client-logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col align-self-center">
                <div className="card border-0">
                  <div className="img-container">
                    <img
                      className="d-block m-auto img-fluid"
                      src={LogoLippo}
                      alt="client-logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col align-self-center">
                <div className="card border-0">
                  <div className="img-container">
                    <img
                      className="d-block m-auto img-fluid"
                      src={LogoLoreal}
                      alt="client-logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col align-self-center">
                <div className="card border-0">
                  <div className="img-container">
                    <img
                      className="d-block m-auto img-fluid"
                      src={LogoMedistra}
                      alt="client-logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col align-self-center">
                <div className="card border-0">
                  <div className="img-container">
                    <img
                      className="d-block m-auto img-fluid"
                      src={LogoMiraeAsset}
                      alt="client-logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col align-self-center">
                <div className="card border-0">
                  <div className="img-container">
                    <img
                      className="d-block m-auto img-fluid"
                      src={LogoProfira}
                      alt="client-logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col align-self-center">
                <div className="card border-0">
                  <div className="img-container">
                    <img
                      className="d-block m-auto img-fluid"
                      src={LogoSinergiDigital}
                      alt="client-logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col align-self-center">
                <div className="card border-0">
                  <div className="img-container">
                    <img
                      className="d-block m-auto img-fluid"
                      src={LogoSynnex}
                      alt="client-logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col align-self-center">
                <div className="card border-0">
                  <div className="img-container">
                    <img
                      className="d-block m-auto img-fluid"
                      src={LogoThePeak}
                      alt="client-logo"
                    />
                  </div>
                </div>
              </div>
              <div className="col align-self-center">
                <div className="card border-0">
                  <div className="img-container">
                    <img
                      className="d-block m-auto img-fluid"
                      src={LogoUNDSS}
                      alt="client-logo"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
