import { useParams } from "react-router-dom";
import ImgITProduct from "../assets/caseStudy/Ads-ITProduct.png";
import ImgLawFirm from "../assets/caseStudy/Ads-LawFirm.png";
import ImgFoodBeverage from "../assets/caseStudy/Photograph-Food&Beverage.png";
import ImgSkincareBrand from "../assets/caseStudy/SEO-SkinCareBrand.png";
import ImgLogistik from "../assets/caseStudy/SEO-LogistikCompany.png";
import ImgHealthLab from "../assets/caseStudy/SocialMediaManagement-HealthLab.png";
import ImgApartment from "../assets/caseStudy/SMSBLAST-Apartement.png";
import ImgSynnex from "../assets/caseStudy/WebDevPTSynnexMetrodata.png";
import ImgProfira from "../assets/caseStudy/WebDevProfiraClinic.png";

const CaseStudyDetails = () => {
  const { caseId } = useParams();

  const renderTitle = () => {
    return caseId == 1
      ? "Produk IT"
      : caseId == 2
      ? "Firma Hukum"
      : caseId == 3
      ? "Makanan & Minum"
      : caseId == 4
      ? "Merk Perawatan Kulit"
      : caseId == 5
      ? "Perusahaan Logistik"
      : caseId == 6
      ? "Laboratorium Kesehatan"
      : caseId == 7
      ? "Aparthment"
      : caseId == 8
      ? "PT Synnex Metrodata"
      : caseId == 9
      ? "Profia Clinic"
      : null;
  };

  const renderImage = () => {
    return caseId == 1
      ? ImgITProduct
      : caseId == 2
      ? ImgLawFirm
      : caseId == 3
      ? ImgFoodBeverage
      : caseId == 4
      ? ImgSkincareBrand
      : caseId == 5
      ? ImgLogistik
      : caseId == 6
      ? ImgHealthLab
      : caseId == 7
      ? ImgApartment
      : caseId == 8
      ? ImgSynnex
      : caseId == 9
      ? ImgProfira
      : null;
  };

  const renderDescription = () => {
    return caseId == 1
      ? "Zebra Technologies, salah satu IT Product perlu meningkatkan impresi dan penjualannya di Tokopedia. Mereka ingin SEM dengan Tokopedia sebagai landing page."
      : caseId == 2
      ? "Salah satu Lawfirm baru di media sosial ingin meningkatan dengagement dan followers. Iklan berjalan selama 2 hari."
      : caseId == 3
      ? "Pengambilan gambar untuk dan buku menu Maxx Corner dan Wayang Bistro"
      : caseId == 4
      ? "Ini adalah salah satu merk perawatan kulit terbesar di Indonesia. Kami membuat lalu lintas stabil kembali ketika turun karena bersaing dengan pasar dan startup kesehatan."
      : caseId == 5
      ? "Yosua Logistik adalah perusahaan Ekspedisi dan Logistik. Kami melakukan SEO dari awal. Dalam 3 bulan pertama kita bisa menumbuhkan lalu lintas menjadi 200-450 pengunjung per bulan. Mendapatkan 15 pelanggan dari website dimana 7 pelanggan melakukan repeat order."
      : caseId == 6
      ? "Pertahankan resence dan konten media sosial dengan posting 15-20 konten per bulan."
      : caseId == 7
      ? "Apartment The Peak Sudirman merupakan kawasan hunian di Jakarta Selatan yang terus berupaya memberikan pelayanan terbaik. Mereka memberikan pelayanan yang berkualitas sehingga memudahkan penyewa apartemen untuk lebih mengetahui pembayaran tepat waktu."
      : caseId == 8
      ? "Merupakan perusahaan IT Distributor yang memiliki kebutuhan pembuatan website dibeberapa anak groupnya. Melalui website tersebut dapat membantu perusahaan mengembangkan pemasarannya melalui SEO di google ataupun untuk dipakai iklan di layanan google adwords."
      : caseId == 9
      ? "Merupakan sebuah klinik kecantikan yang sudah memiliki beberapa cabang-cabang di seluruh Indonesia. Dengan memiliki website memudahkan klinik tersebut menjangkau pelanggan diseluruh Indonesia."
      : null;
  };

  return (
    <div className="container py-5">
      <div className="mb-3">
        <h1 className="text-black fs-32">{renderTitle()}</h1>
        <div className="img-fluid border border-1 border-dark rounded-3">
          <img className="d-block m-auto" src={renderImage()} alt="case-img" />
        </div>
      </div>
      <div className="mb-3">
        <p>{renderDescription()}</p>
      </div>
    </div>
  );
};

export default CaseStudyDetails;
