// Components
import Banner from "../components/Banner";
import Accordion from "react-bootstrap/Accordion";
import ScrollAnimation from "react-animate-on-scroll";

// Icon
import { MdFacebook } from "react-icons/md";
import { FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import ImgHalfRound1 from "../assets/decoration/half-round-3d.png";
import ImgHalfRound2 from "../assets/decoration/half-round-2.png";
import ImgHeartShape from "../assets/decoration/heart-shape.png";
import ImgOrnament1 from "../assets/decoration/ornament-1.png";
import ImgEllipse1 from "../assets/decoration/ellipse-1.png";
import ImgEllipse2 from "../assets/decoration/ellipse-2.png";
import ImgPartyPopper from "../assets/decoration/party-popper.png";
import ImgStar1 from "../assets/decoration/star-1.png";
import ImgLine1 from "../assets/decoration/line-1.png";
import ImgBell1 from "../assets/decoration/bell-1.png";
import ImgQuestionMark1 from "../assets/decoration/question-mark-1.png";
import ImgQuestionMark2 from "../assets/decoration/question-mark-2.png";

// Image
import Image1 from "../assets/decoration/dec-home1.png";
import Image2 from "../assets/home/home-phone.png";
import ImgService1 from "../assets/home/home-service-1.png";
import ImgService2 from "../assets/home/home-service-2.png";
import ImgService3 from "../assets/home/home-service-3.png";
import ImgService4 from "../assets/home/home-service-4.png";
import ImgService5 from "../assets/home/home-service-5.png";
import ImgService6 from "../assets/home/home-service-6.png";
import ImgChoose1 from "../assets/home/digital-activation.png";
import ImgChoose2 from "../assets/home/strategy-planning.png";
import ImgChoose3 from "../assets/home/best-solution.png";
import ImgChoose4 from "../assets/home/professional-team.png";
import ImgFaq from "../assets/home/faq.png";

// Styling
import "../index.css";

const Home = () => {
  return (
    <>
      {/* Banner Layout */}
      <Banner>
        <div className="row home-banner">
          <div className="col-6">
            <div className="ms-md-5">
              <div className="banner-text">
                {/* <img src={BannerText1} alt="" /> */}
                <h1 className="fs-62 fw-bold text-white">
                  Kembangkan bisnis anda ke seluruh dunia
                </h1>
                <div className="d-none d-lg-block text-banner-small w-50">
                  <span className="fs-18 fw-bold text-white d-block">
                    Sekarang saatnya anda mengembangkan bisnis anda dengan
                    layanan dan tim profesional yang kami miliki di dunia
                    pemasaran untuk menumbuhkan bisnis yang lebih besar.
                  </span>

                  <div className="d-none d-md-block my-3">
                    <a href="/contact-us">
                      <button className="btn btn-transparent text-white">
                        Yuk Berdiskusi!
                      </button>
                    </a>
                  </div>
                  <div className="d-none d-md-block">
                    <MdFacebook size={20} className="" color="white" />
                    <FaInstagram size={20} className="ms-3" color="white" />
                    <FaLinkedin size={20} className="ms-3" color="white" />
                    <FaWhatsapp size={20} className="ms-3" color="white" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="d-none d-md-block socmed-picture">
              <img src={Image1} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </Banner>

      {/* Main Content */}
      <div className="home position-relative">
        <div className="d-none d-md-block half-round-2">
          <img className="img-fluid" src={ImgHalfRound2} alt="" />
        </div>
        <div className="d-none d-md-block ellipse-1">
          <img className="img-fluid" src={ImgEllipse1} alt="" />
        </div>
        <div className="d-none d-md-block ornament">
          <img className="img-fluid" src={ImgOrnament1} alt="" />
        </div>

        <div className="container position-relative">
          <div className="row" style={{ padding: "8rem 0" }}>
            <div className="col-12 col-md-6 align-self-center">
              <ScrollAnimation animateIn="fadeIn">
                <div className="img-optimize">
                  <img
                    className="d-block m-auto img-fluid"
                    src={Image2}
                    alt=""
                  />
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-12 col-md-6 align-self-center optimize">
              <ScrollAnimation animateIn="fadeIn">
                <div className="fw-bold font-epilogue">
                  <span className="d-block fs-52">Optimalkan bisnis anda</span>
                  <span className="d-block fs-52">bersama kami</span>
                </div>
                <span className="d-block fw-500 text-black">
                  Dengan pengalaman yang kami miliki dan kretifitas tim yang
                  handal,
                </span>
                <span className="d-block fw-500 text-black">
                  Vaya adalah mitra terbaik untuk mengoptimalkan bisnis anda
                </span>
                <span className="d-block fw-500 text-black">
                  dalam menghadapi era digital.
                </span>
                <div className="my-3 ">
                  <button className="btn btn-transparent-black text-dark">
                    Dapatkan Penawaran
                  </button>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <div className="position-relative">
          <div className="d-none d-lg-block ellipse-2">
            <img src={ImgEllipse2} alt="" />
          </div>
          <div className="d-none d-lg-block half-round-1">
            <img src={ImgHalfRound1} alt="" />
          </div>
          <div className="d-none d-lg-block heart-shape">
            <img src={ImgHeartShape} alt="" />
          </div>
          <div className="d-none d-lg-block party-popper">
            <img src={ImgPartyPopper} alt="" />
          </div>
        </div>
        <div className="container position-relative">
          <div className="services py-5">
            <div className="text-center mb-4">
              <h2 className="fs-52 text-black fw-bold font-epilogue text-center">
                Layanan
              </h2>
              <span className="d-block text-black fs-18">Di Agensi Kami</span>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 p-0">
                <div className="content position-relative">
                  <img className="w-100 h-100" src={ImgService1} alt="" />
                  <div className="p-4 services-text bg-linear-3">
                    <div className="text">
                      <h4 className="fs-20">Situs Web Modern</h4>
                      <h1 className="fs-30">PENGEMBANGAN SITUS WEB</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 p-0">
                <div className="content position-relative">
                  <img className="w-100 h-100" src={ImgService2} alt="" />
                  <div className="d-none d-lg-block p-4 services-text bg-linear-1">
                    <div className="text">
                      <h4 className="fs-20">Pemasaran Merk</h4>
                      <h1 className="fs-30">IKLAN DIGITAL</h1>
                    </div>
                  </div>
                </div>
                <div className="content position-relative">
                  <img className="w-100 h-100" src={ImgService3} alt="" />
                  <div className="p-4 services-text bg-linear-2">
                    <div className="text">
                      <h4 className="fs-20">Toko Digital</h4>
                      <h1 className="fs-30">MANAJEMEN E-COMMERCE</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-4 p-0">
                <div className="content position-relative">
                  <img className="w-100 h-100" src={ImgService4} alt="" />
                  <div className="p-4 services-text bg-linear-6">
                    <div className="text">
                      <h4 className="fs-20">Promosi</h4>
                      <h1 className="fs-30">MANAJEMEN SOSIAL MEDIA</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 p-0">
                <div className="content position-relative">
                  <img className="w-100 h-100" src={ImgService5} alt="" />
                  <div className="p-4 services-text bg-linear-4">
                    <div className="text">
                      <h4 className="fs-20">Perluas Bisnis</h4>
                      <h1 className="fs-30">PENGEMBANGAN</h1>
                      <h1 className="fs-30">BISNIS</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 p-0">
                <div className="content position-relative">
                  <img className="w-100 h-100" src={ImgService6} alt="" />
                  <div className="p-4 services-text bg-linear-5">
                    <div className="text">
                      <h4 className="fs-20">Kirim SMS</h4>
                      <h1 className="fs-30 m-0">SMS</h1>
                      <h1 className="fs-30">BLAST</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-none d-md-block position-relative">
          <div className="line-1">
            <img src={ImgLine1} alt="" />
          </div>
          <div className="star-1">
            <img src={ImgStar1} alt="" />
          </div>
        </div>
        <div className="container position-relative">
          <div className="superiority py-5">
            <ScrollAnimation animateIn="flipInY">
              <div className="text-center mb-4">
                <h2 className="fs-52 text-black fw-bold font-epilogue text-center">
                  Kenapa harus Vaya?
                </h2>
                <span className="d-block text-black fs-18">
                  Vaya adalah Rockstar Digital dan Partner Terbaik Anda!
                </span>
              </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="bounceInRight">
              <div className="row row-cols-1 row-cols-md-2 g-4">
                <div className="col">
                  <div className="card border-0">
                    <div className="row g-0">
                      <div className="col-md-6 align-self-center">
                        <img
                          src={ImgChoose1}
                          alt=""
                          className="d-block m-auto img-fluid"
                        />
                      </div>
                      <div className="col-md-6">
                        <div class="card-body">
                          <div className="d-flex">
                            <span className="fs-40 fw-bold">01</span>
                            <div className="ms-2">
                              <span className="d-block fs-24 fw-bold">
                                Aktivasi
                              </span>
                              <span className="d-block fs-24 fw-bold">
                                Digital
                              </span>
                            </div>
                          </div>
                          <p class="card-text">
                            Tim manajemen kami memiliki pengalaman selama
                            puluhan tahun dalam pemasaran online untuk
                            menyediakan strategi pemasaran online terintegrasi
                            yang membantu perusahaan menumbuhkan audiensnya.
                            Kami memiliki pemahaman terbaik tentang budaya
                            internet dengan apresiasi terbaru.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card border-0">
                    <div className="row g-0">
                      <div className="col-md-6 align-self-center">
                        <img
                          src={ImgChoose2}
                          alt=""
                          className="d-block m-auto img-fluid"
                        />
                      </div>
                      <div className="col-md-6">
                        <div class="card-body">
                          <div className="d-flex">
                            <span className="fs-40 fw-bold">02</span>
                            <div className="ms-2">
                              <span className="d-block fs-24 fw-bold">
                                Strategy dan
                              </span>
                              <span className="d-block fs-24 fw-bold">
                                Perencanaan
                              </span>
                            </div>
                          </div>
                          <p class="card-text">
                            Kami menyediakan layanan branding marketing untuk
                            produk jasa anda. Selain itu juga kami mempunyai
                            jasa TVX, Print Ad, Digital Campaign, BTL, Event dan
                            Digital Strategis.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card border-0">
                    <div className="row g-0">
                      <div className="col-md-6 align-self-center">
                        <img
                          src={ImgChoose3}
                          alt=""
                          className="d-block m-auto img-fluid"
                        />
                      </div>
                      <div className="col-md-6">
                        <div class="card-body">
                          <div className="d-flex">
                            <span className="fs-40 fw-bold">03</span>
                            <div className="ms-2 align-self-center">
                              <span className="d-block fs-24 fw-bold">
                                Solusi Terbaik
                              </span>
                            </div>
                          </div>
                          <p class="card-text">
                            Kami telah membantu berbagai macam permasalahan
                            perusahaan di Indonesia dengan menghadirkan solusi
                            digital dengan analisa proses bisnis yang detail,
                            kami yakin sekali dapat membantu meningkatkan bisnis
                            anda di sisi digital.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card border-0">
                    <div className="row g-0">
                      <div className="col-md-6 align-self-center">
                        <img
                          src={ImgChoose4}
                          alt=""
                          className="d-block m-auto img-fluid"
                        />
                      </div>
                      <div className="col-md-6">
                        <div class="card-body">
                          <div className="d-flex">
                            <span className="fs-40 fw-bold">04</span>
                            <div className="ms-2 align-self-center">
                              <span className="d-block fs-24 fw-bold">Tim</span>
                              <span className="d-block fs-24 fw-bold">
                                Professional
                              </span>
                            </div>
                          </div>
                          <p class="card-text">
                            Vaya adalah tim profesional multikultural dan
                            multidisiplin yang berkumpul untuk membentuk tim
                            dengan misi terfokus untuk menciptakan brand yang
                            penuh perasaan di dunia digital dan bersenang-senang
                            saat melakukannya.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </div>
        <div className="d-none d-md-block position-relative">
          <div className="question-mark-1">
            <img src={ImgQuestionMark1} alt="" srcset="" />
          </div>
          <div className="bell-1">
            <img src={ImgBell1} alt="" srcset="" />
          </div>
        </div>

        {/* <div className="container position-relative">
          <div className="faq py-5">
            <div className="row">
              <div className="col-12 col-lg-6">
                <h1 className="fs-52 fw-bold">Frequently Asked Question</h1>
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Attract Phase</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Convert Phase</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Transaction Phase</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Delight Phase</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="col-12 col-lg-6">
                <img
                  className="float-end d-none d-lg-block"
                  src={ImgFaq}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div> */}

        <div className="position-relative">
          <div className="d-none d-lg-block question-mark-2">
            <img src={ImgQuestionMark2} alt="" srcset="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
