import BannerBg1 from "../assets/banner-bg1.png";
const Banner = (props) => {
  return (
    <div className="position-relative">
      <img src={BannerBg1} alt="" className="w-100" />
      {props.children}
    </div>
  );
};

export default Banner;
