// Components
import Banner from "../components/Banner";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";

// Icon
import { FaUserCircle, FaRegCalendarAlt, FaSearch } from "react-icons/fa";
import ImgLightbulb from "../assets/decoration/lightbulb.png";
import ImgEllipse2 from "../assets/decoration/ellipse-2.png";

// Image
import ImgMegaphone from "../assets/news/megaphone.png";
import ImgNews1 from "../assets/news/news-1.png";
import ImgNews2 from "../assets/news/news-2.png";
import ImgNews3 from "../assets/news/news-3.png";
import ImgNews4 from "../assets/news/news-4.png";

import "../pages/News.css";
import "../index.css";

const News = () => {
  return (
    <>
      <Banner>
        <div className="news-banner position-relative">
          <div className="text">
            <h1 className="fs-82 text-white fw-bold">News on Vaya</h1>
            {/* <h1 className="fs-62 text-white fw-bold">on</h1> */}
            {/* <h1 className="fs-62 text-white fw-bold">Vaya</h1> */}
          </div>
          <div className="image">
            <img
              src={ImgMegaphone}
              alt=""
              className="d-none d-md-block img-fluid"
            />
          </div>
        </div>
      </Banner>
      <div className="position-relative">
        <div className="d-none d-md-block news-lightbulb">
          <img src={ImgLightbulb} alt="" />
        </div>
        <div className="d-none d-md-block news-ellipse-2">
          <img src={ImgEllipse2} alt="" />
        </div>
      </div>
      <div className="container" style={{ padding: "8rem 0" }}>
        <div>
          <InputGroup className="mb-3 px-2">
            <DropdownButton
              variant="outline-secondary"
              title="Category"
              id="input-group-dropdown-1"
            >
              <Dropdown.Item href="#">Action</Dropdown.Item>
              <Dropdown.Item href="#">Another action</Dropdown.Item>
              <Dropdown.Item href="#">Something else here</Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item href="#">Separated link</Dropdown.Item>
            </DropdownButton>
            <Form.Control aria-label="" placeholder="search news here" />
            <Button variant="outline-secondary" id="button-addon2">
              <FaSearch />
            </Button>
          </InputGroup>
        </div>
        <div className="mt-5 news-section">
          <div className="text-center mb-5">
            <h4 className="text-black fs-24 fw-bold">News</h4>
          </div>
          <div className="row row-cols-1 row-cols-md-4 g-4">
            <div className="col">
              <div className="card border-0">
                <img src={ImgNews1} className="card-img-top" alt="" />
                <div className="card-body">
                  <a href="/news-detail">
                    <h5 class="card-title">
                      Strategi Marketing Selama Pandemi
                    </h5>
                  </a>
                  <div className="d-flex align-items-center">
                    <FaUserCircle />
                    <p className="card-text m-0 ms-2">Vayaio</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <FaRegCalendarAlt />
                    <p className="card-text m-0 ms-2">10/08/2022</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0">
                <img src={ImgNews2} className="card-img-top" alt="" />
                <div className="card-body">
                  <a href="/news-detail">
                    <h5 class="card-title">
                      Aktivitas Belanja Internet Naik 69%
                    </h5>
                  </a>
                  <div className="d-flex align-items-center">
                    <FaUserCircle />
                    <p className="card-text m-0 ms-2">Vayaio</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <FaRegCalendarAlt />
                    <p className="card-text m-0 ms-2">10/08/2022</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0">
                <img src={ImgNews3} className="card-img-top" alt="" />
                <div className="card-body">
                  <a href="/news-detail">
                    <h5 class="card-title">Kelebihan Digital Marketing</h5>
                  </a>
                  <div className="d-flex align-items-center">
                    <FaUserCircle />
                    <p className="card-text m-0 ms-2">Vayaio</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <FaRegCalendarAlt />
                    <p className="card-text m-0 ms-2">10/08/2022</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0">
                <img src={ImgNews4} className="card-img-top" alt="" />
                <div className="card-body">
                  <a href="/news-detail">
                    <h5 class="card-title">Jenis-jenis Digital Marketing</h5>
                  </a>
                  <div className="d-flex align-items-center">
                    <FaUserCircle />
                    <p className="card-text m-0 ms-2">Vayaio</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <FaRegCalendarAlt />
                    <p className="card-text m-0 ms-2">10/08/2022</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0">
                <img src={ImgNews1} className="card-img-top" alt="" />
                <div className="card-body">
                  <a href="/news-detail">
                    <h5 class="card-title">
                      Strategi Marketing Selama Pandemi
                    </h5>
                  </a>
                  <div className="d-flex align-items-center">
                    <FaUserCircle />
                    <p className="card-text m-0 ms-2">Vayaio</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <FaRegCalendarAlt />
                    <p className="card-text m-0 ms-2">10/08/2022</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0">
                <img src={ImgNews2} className="card-img-top" alt="" />
                <div className="card-body">
                  <a href="/news-detail">
                    <h5 class="card-title">
                      Aktivitas Belanja Internet Naik 69%
                    </h5>
                  </a>
                  <div className="d-flex align-items-center">
                    <FaUserCircle />
                    <p className="card-text m-0 ms-2">Vayaio</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <FaRegCalendarAlt />
                    <p className="card-text m-0 ms-2">10/08/2022</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0">
                <img src={ImgNews3} className="card-img-top" alt="" />
                <div className="card-body">
                  <a href="/news-detail">
                    <h5 class="card-title">Kelebihan Digital Marketing</h5>
                  </a>
                  <div className="d-flex align-items-center">
                    <FaUserCircle />
                    <p className="card-text m-0 ms-2">Vayaio</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <FaRegCalendarAlt />
                    <p className="card-text m-0 ms-2">10/08/2022</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card border-0">
                <img src={ImgNews4} className="card-img-top" alt="" />
                <div className="card-body">
                  <a href="/news-detail">
                    <h5 class="card-title">Jenis-jenis Digital Marketing</h5>
                  </a>
                  <div className="d-flex align-items-center">
                    <FaUserCircle />
                    <p className="card-text m-0 ms-2">Vayaio</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <FaRegCalendarAlt />
                    <p className="card-text m-0 ms-2">10/08/2022</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default News;
