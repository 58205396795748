// Image
import ServicesDetailBanner from "../assets/service-detail.png";
import ServicesDetailContent1 from "../assets/services-content1.png";
import ServicesDetailContent2 from "../assets/services-content2.png";

const ServicesDetail = () => {
  return (
    <div className="container py-5">
      <div className="mb-3">
        <h3 className="text-black fs-32">Website Development</h3>
        <div className="img-fluid">
          <img className="w-100 h-auto" src={ServicesDetailBanner} alt="" />
        </div>
      </div>
      <div className="mb-3">
        <h3 className="text-black fs-32">Lorem ipsum dolor sit amet</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime
          voluptas id soluta est quas, architecto fugiat libero ratione,
          exercitationem harum, itaque et! Ab itaque aliquam molestias quos
          neque iste quidem.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime
          voluptas id soluta est quas, architecto fugiat libero ratione,
          exercitationem harum, itaque et! Ab itaque aliquam molestias quos
          neque iste quidem.
        </p>
        <div className="row">
          <div className="col-12 col-md-6">
            <div>
              <img
                className="w-100 h-auto"
                src={ServicesDetailContent1}
                alt=""
              />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
              <img
                className="w-100 h-auto"
                src={ServicesDetailContent2}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-3">
        <h3 className="text-black fs-32">Lorem ipsum dolor sit amet</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quaerat
          explicabo quis vel? Aliquam velit neque nulla culpa officiis libero
          pariatur laudantium possimus. Quod qui libero eveniet quasi.
          Doloremque, vitae.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quaerat
          explicabo quis vel? Aliquam velit neque nulla culpa officiis libero
          pariatur laudantium possimus. Quod qui libero eveniet quasi.
          Doloremque, vitae.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quaerat
          explicabo quis vel? Aliquam velit neque nulla culpa officiis libero
          pariatur laudantium possimus. Quod qui libero eveniet quasi.
          Doloremque, vitae.
        </p>
      </div>
    </div>
  );
};

export default ServicesDetail;
