import Banner from "../components/Banner";

import bannerImg from "../assets/portfolio/banner-img.png";
import ImgRectangle1 from "../assets/portfolio/rectangle1.png";
import ImgRectangle2 from "../assets/portfolio/rectangle2.png";
import ImgRectangle3 from "../assets/portfolio/rectangle3.png";
import ImgRectangle4 from "../assets/portfolio/rectangle4.png";
import ImgLaptop from "../assets/portfolio/laptop.png";
import ImgBook from "../assets/portfolio/book.png";
import Img2Phone from "../assets/portfolio/2-phone.png";
import Img3Phone from "../assets/portfolio/3-phone.png";
import ImgCss from "../assets/portfolio/css.png";
import ImgCode from "../assets/portfolio/code.png";
import ImgLightbulb from "../assets/decoration/lightbulb.png"
import ImgBell from "../assets/decoration/bell-1.png"
import ImgStar3 from "../assets/decoration/star-3.png"
import ImgHeartShape from "../assets/decoration/heart-shape.png"
import ImgRocket from "../assets/portfolio/rocket.png"
import ImgChatBubbles from "../assets/portfolio/chat-bubbles.png"
import ImgTube from "../assets/decoration/tube.png"
import ImgBox3D from "../assets/decoration/box3d.png"
import ImgEllipse3 from "../assets/decoration/ellipse-3.png"
import ImgEllipse1 from "../assets/decoration/ellipse-1.png"

// Styling
import "../pages/Portfolio.css";

const Portfolio = () => {
  return (
    <>
      <Banner>
        <div className="container portfolio-banner">
          <div className="row">
            <div className="col-6 align-self-center">
              <div className="banner-text-portfolio">
                <span className="d-block fs-62 text-white fw-bold fs-62 fw-bold font-epilogue">
                  Our
                </span>
                <span className="d-block fs-62 text-white fw-bold fs-62 fw-bold font-epilogue">
                  Portfolio
                </span>
                <span className="d-block text-white fs-18">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit
                  morbi quis viverra libero, non. Leo commodo, diam in eget
                  commodo amet, mauris, amet.
                </span>
              </div>
            </div>
            <div className="col-6">
              <div>
                <img className="img-fluid" src={bannerImg} alt="" />
              </div>
            </div>
          </div>
        </div>
      </Banner>
      <div className="position-relative">
        <div className="half-ellipse-portfolio">
          <img src={ImgEllipse3} alt="" />
        </div>
        <div className="ellipse-full-portfolio">
          <img src={ImgEllipse1} alt="" />
        </div>
      </div>
      <div className="container portfolio">
        <div style={{ padding: "8rem 0" }}>
          <div className="row row-cols-1 row-cols-md-2 g-2">
            <div className="col">
              <div className="card">
                <h1 className="fs-48 text-top text-white">WEBSITE</h1>
                <div className="img-code">
                  <img src={ImgCode} alt="" />
                </div>
                <img src={ImgRectangle1} alt="" className="" />
                <div className="position-relative">
                  <div className="img-laptop">
                    <img src={ImgLaptop} alt="" />
                  </div>
                </div>
                <h1 className="fs-48 text-bottom text-white">DEVELOPER</h1>
                <div className="img-css">
                  <img src={ImgCss} alt="" />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <h1 className="fs-48 text-top text-white">COMPANY</h1>
                <div className="img-bell">
                  <img src={ImgBell} alt="" />
                </div>
                <img src={ImgRectangle2} alt="" className="" />
                <div className="position-relative">
                  <div className="img-laptop">
                    <img src={ImgBook} alt="" />
                  </div>
                </div>
                <h1 className="fs-48 text-bottom text-white">PROFILE</h1>
                <div className="img-lightbulb">
                  <img src={ImgLightbulb} alt="" />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <h1 className="fs-48 text-top text-white">SOCIAL</h1>
                <div className="img-star">
                  <img src={ImgStar3} alt="" />
                </div>
                <img src={ImgRectangle3} alt="" className="" />
                <div className="position-relative">
                  <div className="img-laptop">
                    <img src={Img2Phone} alt="" />
                  </div>
                </div>
                <h1 className="fs-48 text-bottom text-white">MEDIA</h1>
                <div className="img-heartshape">
                  <img src={ImgHeartShape} alt="" />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card">
                <h1 className="fs-48 text-top text-white">SMS</h1>
                <div className="img-chat-bubbles">
                  <img src={ImgChatBubbles} alt="" />
                </div>
                <img src={ImgRectangle4} alt="" className="" />
                <div className="position-relative">
                  <div className="img-laptop">
                    <img src={Img3Phone} alt="" />
                  </div>
                </div>
                <h1 className="fs-48 text-bottom text-white">BLAST</h1>
                <div className="img-rocket">
                  <img src={ImgRocket} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <div className="img-tube">
          <img src={ImgTube} alt="" />
        </div>
        <div className="img-box3d">
          <img src={ImgBox3D} alt="" />
        </div>
      </div>
    </>
  );
};

export default Portfolio;
