// Image
import NewsDetailBanner from "../assets/service-detail.png";
import NewsDetailContent1 from "../assets/services-content1.png";
import NewsDetailContent2 from "../assets/services-content2.png";
import ImgNews1 from "../assets/news/news-1.png";
import ImgNews2 from "../assets/news/news-2.png";
import ImgNews3 from "../assets/news/news-3.png";
import ImgNews4 from "../assets/news/news-4.png";

// Icons
import { FaUserCircle, FaRegCalendarAlt, FaSearch } from "react-icons/fa";

const NewsDetail = () => {
  return (
    <div className="container py-5">
      <div className="mb-3">
        <h3 className="text-black fs-32 fw-bold">
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
        </h3>
        <p className="">By Anonymous | 10/08/2022 21.50 WIB</p>
        <div className="img-fluid">
          <img src={NewsDetailBanner} alt="" className="w-100 h-auto" />
        </div>
      </div>
      <div className="mb-3">
        <h3 className="text-black fs-32">Lorem ipsum dolor sit amet</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime
          voluptas id soluta est quas, architecto fugiat libero ratione,
          exercitationem harum, itaque et! Ab itaque aliquam molestias quos
          neque iste quidem.
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime
          voluptas id soluta est quas, architecto fugiat libero ratione,
          exercitationem harum, itaque et! Ab itaque aliquam molestias quos
          neque iste quidem.
        </p>
        <div className="row">
          <div className="col-12 col-md-6">
            <div>
              <img className="w-100 h-auto" src={NewsDetailContent1} alt="" />
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div>
              <img className="w-100 h-auto" src={NewsDetailContent2} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5">
        <h3 className="text-black fs-32">Lorem ipsum dolor sit amet</h3>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quaerat
          explicabo quis vel? Aliquam velit neque nulla culpa officiis libero
          pariatur laudantium possimus. Quod qui libero eveniet quasi.
          Doloremque, vitae.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quaerat
          explicabo quis vel? Aliquam velit neque nulla culpa officiis libero
          pariatur laudantium possimus. Quod qui libero eveniet quasi.
          Doloremque, vitae.
        </p>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum quaerat
          explicabo quis vel? Aliquam velit neque nulla culpa officiis libero
          pariatur laudantium possimus. Quod qui libero eveniet quasi.
          Doloremque, vitae.
        </p>
        <span className="text-black fw-bold">Category</span>
        <span className="text-white bg-linear-1 p-1 ms-2 rounded">
          Website Dev
        </span>
      </div>
      <div className="mb-3">
        <h4 className="fw-bold fs-32 text-center">More News</h4>
        <div className="row row-cols-1 row-cols-md-4 g-4">
          <div className="col">
            <div className="card border-0">
              <img src={ImgNews1} className="card-img-top" alt="" />
              <div className="card-body">
                <h5 class="card-title">Strategi Marketing Selama Pandemi</h5>
                <div className="d-flex align-items-center">
                  <FaUserCircle />
                  <p className="card-text m-0 ms-2">Vayaio</p>
                </div>
                <div className="d-flex align-items-center">
                  <FaRegCalendarAlt />
                  <p className="card-text m-0 ms-2">10/08/2022</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
              <img src={ImgNews2} className="card-img-top" alt="" />
              <div className="card-body">
                <h5 class="card-title">Aktivitas Belanja Internet Naik 69%</h5>
                <div className="d-flex align-items-center">
                  <FaUserCircle />
                  <p className="card-text m-0 ms-2">Vayaio</p>
                </div>
                <div className="d-flex align-items-center">
                  <FaRegCalendarAlt />
                  <p className="card-text m-0 ms-2">10/08/2022</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
              <img src={ImgNews3} className="card-img-top" alt="" />
              <div className="card-body">
                <h5 class="card-title">Kelebihan Digital Marketing</h5>
                <div className="d-flex align-items-center">
                  <FaUserCircle />
                  <p className="card-text m-0 ms-2">Vayaio</p>
                </div>
                <div className="d-flex align-items-center">
                  <FaRegCalendarAlt />
                  <p className="card-text m-0 ms-2">10/08/2022</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card border-0">
              <img src={ImgNews4} className="card-img-top" alt="" />
              <div className="card-body">
                <h5 class="card-title">Jenis-jenis Digital Marketing</h5>
                <div className="d-flex align-items-center">
                  <FaUserCircle />
                  <p className="card-text m-0 ms-2">Vayaio</p>
                </div>
                <div className="d-flex align-items-center">
                  <FaRegCalendarAlt />
                  <p className="card-text m-0 ms-2">10/08/2022</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
