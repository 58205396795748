// Components
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

// Pages
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import CaseStudy from "./pages/CaseStudy";
import CaseStudyDetails from "./components/CaseStudyDetails";
import ContactUs from "./pages/ContactUs";
import News from "./pages/News";
import ServicesDetail from "./pages/ServicesDetail";
import NewsDetail from "./pages/NewsDetail";

function App() {
  return (
    <div>
      <Navbar />
      {/* <Sidebar/> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="services" element={<Services />} />
        <Route path="services-detail" element={<ServicesDetail />} />
        <Route path="portfolio" element={<Portfolio />} />
        <Route path="case-study" element={<CaseStudy />} />
        <Route path="case-study/:caseId" element={<CaseStudyDetails />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="news" element={<News />} />
        <Route path="news-detail" element={<NewsDetail />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
