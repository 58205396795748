import ImgITProduct from "../../assets/caseStudy/Ads-ITProduct.png"
import ImgLawFirm from "../../assets/caseStudy/Ads-LawFirm.png"
import ImgFoodBeverage from "../../assets/caseStudy/Photograph-Food&Beverage.png"
import ImgSkincareBrand from "../../assets/caseStudy/SEO-SkinCareBrand.png"
import ImgLogistik from "../../assets/caseStudy/SEO-LogistikCompany.png"
import ImgHealthLab from "../../assets/caseStudy/SocialMediaManagement-HealthLab.png"
import ImgApartment from "../../assets/caseStudy/SMSBLAST-Apartement.png"
import ImgSynnex from "../../assets/caseStudy/WebDevPTSynnexMetrodata.png"
import ImgProfira from "../../assets/caseStudy/WebDevProfiraClinic.png"


export const caseStudyData = [
    {
        id: 1,
        title: "Iklan Berbayar Digital - Produkt IT",
        description: "Zebra Technologies, salah satu IT Product perlu meningkatkan impresi dan penjualannya di Tokopedia. Mereka ingin SEM dengan Tokopedia sebagai landing page.",
        image: ImgITProduct,
    },
    {
        id: 2,
        title: "Iklan Berbayar Digital - Firma Hukum",
        description: "Salah satu Lawfirm baru di media sosial ingin meningkatan dengagement dan followers. Iklan berjalan selama 2 hari.",
        image: ImgLawFirm,
    },
    {
        id: 3,
        title: "Foto - Makanan & Minum",
        description: "Pengambilan gambar untuk dan buku menu Maxx Corner dan Wayang Bistro",
        image: ImgFoodBeverage,
    },
    {
        id: 4,
        title: "SEO - Merk Perawatan Kulit",
        description: "Ini adalah salah satu merk perawatan kulit terbesar di Indonesia. Kami membuat lalu lintas stabil kembali ketika turun karena bersaing dengan pasar dan startup kesehatan.",
        image: ImgSkincareBrand,
    },
    {
        id: 5,
        title: "SEO - Perusahaan Logistik",
        description: "Yosua Logistik adalah perusahaan Ekspedisi dan Logistik. Kami melakukan SEO dari awal. Dalam 3 bulan pertama kita bisa menumbuhkan lalu lintas menjadi 200-450 pengunjung per bulan. Mendapatkan 15 pelanggan dari website dimana 7 pelanggan melakukan repeat order.",
        image: ImgLogistik,
    },
    {
        id: 6,
        title: "Manajemen Sosial Media - Laboratorium Kesehatan",
        description: "Pertahankan resence dan konten media sosial dengan posting 15-20 konten per bulan.",
        image: ImgHealthLab,
    },
    {
        id: 7,
        title: "SMS Blast - Apartment",
        description: "Apartment The Peak Sudirman merupakan kawasan hunian di Jakarta Selatan yang terus berupaya memberikan pelayanan terbaik. Mereka memberikan pelayanan yang berkualitas sehingga memudahkan penyewa apartemen untuk lebih mengetahui pembayaran tepat waktu.",
        image: ImgApartment,
    },
    {
        id: 8,
        title: "Pembuatan Website - PT Synnex Metrodata",
        description: "Merupakan perusahaan IT Distributor yang memiliki kebutuhan pembuatan website dibeberapa anak groupnya. Melalui website tersebut dapat membantu perusahaan mengembangkan pemasarannya melalui SEO di google ataupun untuk dipakai iklan di layanan google adwords.",
        image: ImgSynnex,
    },
    {
        id: 9,
        title: "Pembuatan Website - Profia Clinic",
        description: "Merupakan sebuah klinik kecantikan yang sudah memiliki beberapa cabang-cabang di seluruh Indonesia. Dengan memiliki website memudahkan klinik tersebut menjangkau pelanggan diseluruh Indonesia.",
        image: ImgProfira,
    }
]