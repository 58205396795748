// Components
import Banner from "../components/Banner";

// Image
import ImgBanner from "../assets/contactUs/banner-contact-us.png";
import ImgFormFeedback from "../assets/contactUs/feedback-form.png";
import ImgStarHalf from "../assets/decoration/star-half.png";
import ImgTube from "../assets/decoration/tube.png";
import ImgHalfRound3D from "../assets/decoration/half-round-3d.png";
import ImgBox3D from "../assets/decoration/box3d.png";

// Icon
import { MdLocationPin, MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

import "../pages/ContactUs.css";
import "../index.css";

const ContactUs = () => {
  return (
    <>
      <Banner>
        <div className="container">
          <div className="contact-us-banner">
            <div className="row">
              <div className="col-12 col-md-6 align-self-center">
                <h2 className="text-white fs-62 fw-bold m-0">Let</h2>
                <h2 className="text-white fs-62 fw-bold m-0">Contact Us!</h2>
              </div>
              <div className="d-none d-lg-block col-12 col-md-6">
                <div className="w-100 h-100">
                  <img className="img-fluid" src={ImgBanner} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Banner>
      <div className="container contact-us">
        <h5 className="text-black text-center fs-40 mt-4">
          Apapun tujuan brand kamu, Vaya bisa membantu
        </h5>
        <span className="d-block text-center text-black fw-bold">
         Chat dengan tim kami
        </span>
        <div className="row row-cols-1 row-cols-md-3 g-4 contact-desc">
          <div className="col">
            <div className="border rounded p-2">
              <div className="d-flex align-items-center icon p-2 rounded bg-linear-1 mb-3">
                <MdEmail size={30} color="white" />
                <h5 className="text-white fs-24 m-0 fw-bold ms-2">Email kami!</h5>
              </div>
              <div>
                <span className="d-block text-black fs-18 fw-bold">
                  Email tim kami 
                </span>
                <span className="d-block text-black pb-4">info@vaya.co.id</span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="border rounded p-2">
              <div className="d-flex align-items-center icon p-2 bg-linear-1 mb-3">
                <MdLocationPin size={30} color="white" />
                <h5 className="text-white fs-24 m-0 fw-bold ms-2">Kantor kami!</h5>
              </div>
              <div>
                <span className="d-block text-black fs-18 fw-bold">
                  Kunjungi kami
                </span>
                <span className="d-block text-black">Gedung 88, Lantai 10 Unit E</span>
                <span className="d-block text-black">
                  Jl Raya Kasablanka Kav. 88, DKI Jakarta -12870
                </span>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="border rounded p-2">
              <div className="d-flex align-items-center icon p-2 bg-linear-1 mb-3">
                <FaPhoneAlt size={30} color="white" />
                <h5 className="text-white fs-24 m-0 fw-bold ms-2">Hubungi Kami!</h5>
              </div>
              <div>
                <span className="d-block text-black fs-18 fw-bold">
                  Senin-Jum'at: 08.00WIB - 18.00WIB
                </span>
                <span className="d-block text-black pb-4">+62 813-9088-8231</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <div className="d-none d-md-block contact-tube-3d">
          <img src={ImgTube} alt="" />
        </div>
        <div className="d-none d-md-block contact-half-star">
          <img src={ImgStarHalf} alt="" />
        </div>
      </div>
      <div className="container">
        <div className="row py-5">
          <div className="col-12 col-lg-6">
            <form className="contact-form">
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone"
                />
              </div>
              <div className="mb-3">
                <textarea
                  name=""
                  className="form-control"
                  id=""
                  rows="10"
                ></textarea>
              </div>
              <div className="mb-3 text-end">
                <button className="btn btn-contact-us">SEND</button>
              </div>
            </form>
          </div>
          <div className="col-12 col-md-6 align-self-center">
            <div className="img-fluid">
              <img
                src={ImgFormFeedback}
                alt=""
                className="d-none d-lg-block m-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="position-relative">
        <div className="d-none d-lg-block contact-half-round-3d">
          <img src={ImgHalfRound3D} alt="" />
        </div>
        <div className="d-none d-lg-block contact-box-3d">
          <img src={ImgBox3D} alt="" />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
