import vayaLogo from "../assets/footer-logo.png";
import { MdLocationPin, MdEmail, MdFacebook } from "react-icons/md";
import { FaClock, FaPhoneAlt, FaInstagram, FaLinkedin, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="">
      <div className="bg-black position-relative">
        <div className="decoration container d-flex align-items-center justify-content-between">
          <div>
            <p className="fs-24 m-0 text-white fw-bold">
              Dapatkan strategi yang bagus hari ini
            </p>
          </div>
          <div className="d-flex align-items-center">
            <p className="fs-24 m-0 text-white">Hubungi Kami</p>
            <button className="btn btn-transparent text-white ms-2">
              Mulai Diskusi
            </button>
          </div>
        </div>
        <div className="content">
          <div className="row">
            <div className="col-12 col-md-3">
              <img className="d-block mx-auto mb-3" src={vayaLogo} alt="" />
              <p className="text-white text-justify">
                Vaya memiliki tim yang sangat profesional untuk membantu anda mencapai tujuan pemasaran bisnis anda. Kami menyadari bahwa keberhasilan layanan kami tidak hanya dari sisi kami tetapi juga dari sisi bisnis anda. Vaya hadir untuk memberikan solusi terbaik untuk pemasaran bisnis anda dan memastikan kesuksesan di tangan anda.
              </p>
              <div>
                <MdFacebook color="white" size={25} />
                <FaInstagram color="white" size={25} style={{marginLeft: 20}} />
                <FaLinkedin color="white" size={25} style={{marginLeft: 20}} />
                <FaWhatsapp color="white" size={25} style={{marginLeft: 20}} />
              </div>
            </div>
            <div className="col-12 col-md-3">
              <p className="text-white">Perusahaan</p>
              <p className="text-white">Tentang Kami</p>
              <p className="text-white">Fitur</p>
              <p className="text-white">Harga</p>
            </div>
            <div className="col-12 col-md-3">
              <p className="text-white">Dukungan</p>
              <p className="text-white">FAQ's</p>
              <p className="text-white">Syarat dan Ketentuan</p>
              <p className="text-white">Kebijakan Privasi</p>
              <p className="text-white">Kontak Kami</p>
            </div>
            <div className="col-12 col-md-3">
              <p className="text-white">Bertemu Kami</p>
              <div className="d-flex align-items-center mb-3">
                <MdLocationPin size={30} color="white" />
                <span className="ms-2 text-white">
                  Gedung 88, Lantai 10 unit E Jl Raya Kasablanka Kav. 88, DKI Jakarta - 12870
                </span>
              </div>
              <div className="d-flex align-items-center mb-3">
                <FaClock size={25} color="white" />
                <div className="ms-2">
                  <span className="d-block text-white">
                    Senin–Jum'at: 08:00 WIB–18:00 WIB
                  </span>
                  <span className="d-block text-white">Sabtu-Minggu: Tutup</span>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <FaPhoneAlt size={25} color="white" />
                <div className="ms-2">
                  <span className="d-block text-white">+62 813-9088-8231</span>
                </div>
              </div>
              <div className="d-flex align-items-center mb-3">
                <MdEmail size={25} color="white" />
                <div className="ms-2">
                  <span className="d-block text-white">info@vaya.co.id</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p className="text-white text-center m-0 py-2">
        &copy; 2023 VAYA - Master Digital Marketing
        </p>
      </div>
    </footer>
  );
};

export default Footer;
