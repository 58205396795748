import { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { NavLink } from "react-router-dom";

import Logo from "../assets/vaya-logo.png";
import Sidebar from "./Sidebar";

const Navbar = () => {
  const [expand, setExpand] = useState(false);

  const handleSidebar = () => {
    setExpand(!expand);
    console.log("Hello");
    console.log(expand);
  };
  return (
    <>
      <nav className="navbar nav-expand-md ">
        <div className="container">
          <NavLink class="navbar-brand" to="/">
            <img src={Logo} alt="logo" />
          </NavLink>
          <div>
            <GiHamburgerMenu onClick={handleSidebar} size={25} color="black" role="button" />
          </div>
        </div>
      </nav>
      <Sidebar
        isExpand={expand}
        setIsExpand={setExpand}
        handleSidebar={handleSidebar}
      />
    </>
  );
};

export default Navbar;
